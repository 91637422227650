import AgoraUIKit from 'agora-react-uikit';
import React, { useState } from 'react';
import '../App.css';

function MeetComponent() {
  const [videoCall, setVideoCall] = useState(true);

  const props = {
    rtcProps: {
      appId: 'a26c63f3941c4aa99380a76b8693cd0e',
      channel: 'test',
      token: null,
    },
    callbacks: {
      EndCall: () => {
        setVideoCall(false);
      },
    },
  };

  return (
    <div style={styles.container}>
      {videoCall ? (
        <AgoraUIKit
          rtcProps={props.rtcProps}
          callbacks={props.callbacks}
          styleProps={{
            localBtnContainer: {
              backgroundColor: 'black',
            },
          }}
        />
      ) : null}
    </div>
  );
}

const styles = {
  container: { width: '100vw', height: '100vh', display: 'flex', flex: 1 },
};

export default MeetComponent;
