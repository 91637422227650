import { Alert, Col, Layout, Row, Space } from 'antd';
import React from 'react';
import HeaderComponent from '../../../components/header/header';

function NoPermissionErrorComponent() {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <HeaderComponent />

      <Layout
        style={{
          backgroundColor: '#fff',
          marginTop: '120px',
        }}
      >
        <Row>
          <Col span={12} offset={6}>
            <Alert
              style={{
                textAlign: 'center',
              }}
              message="You need to grant camera and microphone permissions to proceed."
              type="error"
              closable={false}
            />
          </Col>
        </Row>
      </Layout>
    </Space>
  );
}

export default NoPermissionErrorComponent;
