import { Image, Layout } from 'antd';
import React from 'react';
const { Header } = Layout;

function HeaderComponent() {
  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        color: 'white',
        fontFamily: 'Helvetica',
        fontWeight: 300,
        marginLeft: '75px',
        marginRight: '80px',
        marginTop: '15px',
      }}
    >
      <div
        style={{
          marginRight: 'auto',
          fontSize: '150%',
          padding: '12px 16px',
          ...navBarItemStyle,
        }}
      >
        <Image src="/assets/logo.png" width={130} preview={false} />
      </div>
      <div
        style={{
          ...navBarItemStyle,
          color: '#000',
          fontWeight: '500',
          fontSize: '1.2rem',
          marginRight: '30px',
        }}
      >
        <a
          href="https://realmarket.com"
          style={{
            color: '#000',
          }}
        >
          Home
        </a>
      </div>
      <div
        style={{
          ...navBarItemStyle,
          color: '#000',
          fontWeight: '500',
          fontSize: '1.2rem',
          marginRight: '30px',
        }}
      >
        <a
          href="https://realmarket.com/contact-us"
          style={{
            color: '#000',
          }}
        >
          Contact Us
        </a>
      </div>
      <div
        style={{
          ...navBarItemStyle,
          color: '#000',
          fontWeight: '500',
          fontSize: '1.2rem',
          marginRight: '30px',
        }}
      >
        <a
          href="https://realmarket.com/agent-sign-up"
          style={{
            color: '#000',
          }}
        >
          Agent Sign Up
        </a>
      </div>
    </Header>
  );
}

const navBarItemStyle = {
  padding: '16px 16px',
  cursor: 'pointer',
  verticalAlign: 'middle',
};

export default HeaderComponent;
