import React from 'react';

function TestCamera() {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    const currentVideoRef = videoRef.current;

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (currentVideoRef) {
          currentVideoRef.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error('Error accessing the camera.', error);
      });

    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        const tracks = currentVideoRef.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return <video ref={videoRef} autoPlay muted />;
}

export default TestCamera;
