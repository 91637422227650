import { AudioOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import React, { useState, useEffect } from 'react';

function TestMicrophone() {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);

        javascriptNode.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const values = array.reduce((a, b) => a + b);
          setAudioLevel(values / array.length);
        };
      })
      .catch((error) => {
        console.error('Error accessing the microphone.', error);
      });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <AudioOutlined
        style={{
          fontSize: '20px',
          marginRight: '10px',
        }}
      />
      <Progress
        percent={Number(audioLevel.toFixed(2))}
        steps={35}
        showInfo={false}
        strokeColor={'black'}
      />
    </div>
  );
}

export default TestMicrophone;
