import { Alert, Col, Layout, Row, Space } from 'antd';
import React from 'react';
import HeaderComponent from '../../../components/header/header';

function CheckingPermissionComponent() {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <HeaderComponent />

      <Layout
        style={{
          backgroundColor: '#fff',
          marginTop: '120px',
        }}
      >
        <Row>
          <Col span={12} offset={6}>
            <Alert
              style={{
                textAlign: 'center',
              }}
              message="Checking Camera and Microphone permissions"
              type="warning"
              closable={false}
            />
          </Col>
        </Row>
      </Layout>
    </Space>
  );
}

export default CheckingPermissionComponent;
