import AgoraUIKit from 'agora-react-uikit';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { BASE_URL } from '../ENV';
import SocketContext from '../contexts/socket-context';
import './users.css';

Modal.setAppElement('#root');

function UsersComponent() {
  const [users, setUsers] = useState([]);
  const { connectSocket } = useContext(SocketContext);
  //   const [isModalOpen, setModalOpen] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isCallingModalOpen, setCallingModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [name, setName] = useState('test');
  const [email, setEmail] = useState('test_email');
  const [videoCall, setVideoCall] = useState(false);
  const [socketIO, setSocketIO] = useState(null);
  const [agoraChannelId, setAgoraChannelId] = useState(null);

  const props = {
    rtcProps: {
      appId: 'a26c63f3941c4aa99380a76b8693cd0e',
      channel: 'test',
      token: null,
      mode: 'rtc',
      codec: 'vp8',
      role: 'host',
    },
    callbacks: {
      EndCall: () => {
        setVideoCall(false);
        setVideoModalOpen(false);

        const URL = BASE_URL + '/socket/call-ended';

        fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callId: agoraChannelId,
            isAgent: false,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log('data', data);
          });
        // socket.emit('call-end', { toUser: selectedEmail });

        // window.location.href = '/users';
      },
    },
  };

  useEffect(() => {
    const URL = BASE_URL + '/agents/available-agents';
    fetch(URL)
      .then((res) => res.json())
      .then((data) => {
        console.log('data', data);
        setUsers(data);
      });
  }, []);

  useEffect(() => {
    if (socketIO) {
      console.log('SOCKET_IO', socketIO);

      socketIO.on('call-accept', (message) => {
        console.log('Received call accept:', message);

        const { callId } = message;

        setAgoraChannelId(callId);

        props.rtcProps.channel = callId;
        console.log('props.rtcProps', props.rtcProps);
        closeCallingModal();
        setVideoModalOpen(true);
        setVideoCall(true);
      });

      socketIO.on('call-end', (message) => {
        console.log('Received call end:', message);

        setVideoCall(false);
        setVideoModalOpen(false);

        // alert('Call ended');
      });

      socketIO.on('no-agent-found', (message) => {
        console.log('Received no agent found:', message);
        closeCallingModal();
        alert('No agent found');
      });
    }
  }, [socketIO, props.rtcProps]);

  async function handleStartCall() {
    if (!name || !email) {
      alert('Please enter name and email');
      return;
    }

    if (!selectedEmail) {
      alert('Please select a user');
      return;
    }

    const _socket = await connectSocket(name, email, '');

    _socket.emit('call-request', { toUser: selectedEmail });

    setSocketIO(_socket);

    openCallingModal();
  }

  const openCallingModal = () => {
    setCallingModalOpen(true);
  };

  const closeCallingModal = () => {
    setCallingModalOpen(false);
  };

  //   const openModal = () => {
  //     setModalOpen(true);
  //   };

  //   const closeModal = () => {
  //     setModalOpen(false);
  //   };

  const closeVideoModal = () => {
    setVideoModalOpen(false);
  };

  //   const handleAccept = () => {
  //     console.log('Accepted');
  //     closeModal();

  //     socket.emit('call-accept', { message: 'Call accepted' });

  //     window.location.href = '/meet';
  //   };

  //   const handleReject = () => {
  //     console.log('Rejected');
  //     closeModal();

  //     socket.emit('call-reject', { message: 'Call rejected' });
  //   };

  const handleChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const inputStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px',
    width: '250px',
    outline: 'none',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontSize: '16px',
  };

  const selectStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px',
    width: '100%',
    outline: 'none',
  };

  const optionStyle = {
    padding: '10px',
    fontSize: '16px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#ffffff',
    cursor: 'pointer',
    outline: 'none',
  };

  return (
    <div style={centerStyle}>
      {/* <h1>Users</h1> */}
      {/* <Link to="/meet">Start Call</Link> */}
      <div>
        <label style={labelStyle} htmlFor="name">
          Name
        </label>
        <input
          id="name"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={inputStyle}
        />

        <br />
        <br />

        <label style={labelStyle} htmlFor="email">
          Email
        </label>

        <input
          id="email"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />

        <br />
        <br />

        <select
          style={selectStyle}
          value={selectedEmail}
          onChange={handleChange}
        >
          <option style={optionStyle} value="" disabled>
            Select a agent
          </option>
          {users.map((user) => (
            <option style={optionStyle} key={user.email} value={user.email}>
              {user.name} - ({user.availableStatus})
            </option>
          ))}
        </select>
        <p>
          <b>Selected Agent: </b> {selectedEmail}
        </p>
        <button style={buttonStyle} onClick={handleStartCall}>
          Start Call
        </button>
      </div>
      <br />
      <br />

      <Modal
        isOpen={isCallingModalOpen}
        onRequestClose={closeCallingModal}
        contentLabel="Calling Modal"
      >
        <div className="modal-overlay">
          <div className="modal">
            <h2>Calling...</h2>
            <p>Please wait for the agent to accept the call</p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={closeVideoModal}
        contentLabel="Call Modal"
      >
        <div className="modal-overlay">
          <div className="modal-call">
            <div style={styles.container}>
              {videoCall ? (
                <AgoraUIKit
                  rtcProps={props.rtcProps}
                  callbacks={props.callbacks}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
    objectFit: 'none',
  },
};

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default UsersComponent;
