import React from 'react';
import { Routes, Route, Outlet, BrowserRouter } from 'react-router-dom';
import UsersComponent from './components/users';
import MeetComponent from './components/meet';
import { SocketProvider } from './contexts/socket-context';
import JoinCallPage from './pages/join-call/join-call';
import VideoCallComponent from './pages/video-call/video-call';

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <SocketProvider>
        <div>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<UsersComponent />} />
              <Route path="users" element={<UsersComponent />} />
              <Route path="meet" element={<MeetComponent />} />
              <Route path="join-call" element={<JoinCallPage />} />
              <Route path="video-call" element={<VideoCallComponent />} />

              <Route path="*" element={<UsersComponent />} />
            </Route>
          </Routes>
        </div>
      </SocketProvider>
    </BrowserRouter>
  );
}

export default App;
