import { createContext, useState } from 'react';
import io from 'socket.io-client';
import { BASE_URL } from '../ENV';

const SocketContext = createContext();

export default SocketContext;

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const connectSocket = async (name, email, contact_id) => {
    const connectionOptions = {
      query: {
        name: name,
        email: email,
        contact_id: contact_id,
      },
    };

    const newSocket = await io(`${BASE_URL}/room`, connectionOptions);
    setSocket(newSocket);

    newSocket.on('disconnect', () => {
      setSocket(null);
    });

    return newSocket;
  };

  const emitEvent = (event, data) => {
    console.log(event);
    socket.emit(event, data);
  };

  return (
    <SocketContext.Provider value={{ socket, connectSocket, emitEvent }}>
      {children}
    </SocketContext.Provider>
  );
};
