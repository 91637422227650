import { Button, Card, Layout, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderComponent from '../../components/header/header';
import CheckingPermissionComponent from './components/checking-permission';
import NoPermissionErrorComponent from './components/no-permission-error';
import TestCamera from './test-media/test-camera';
import TestMicrophone from './test-media/test-microphone';
const { Content } = Layout;
const { Text } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function JoinCallPage() {
  const [hasPermissions, setHasPermissions] = useState(null);

  const query = useQuery();
  const agent = query.get('agent');
  const name = query.get('name');
  const email = query.get('email');
  const contact_id = query.get('contact_id');

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => {
        setHasPermissions(true);
      })
      .catch((error) => {
        console.error('Error accessing media devices.', error);
        setHasPermissions(false);
      });
  }, []);

  const startCall = () => {
    window.location.href = `/video-call?name=${name}&email=${email}&agent=${agent}&contact_id=${contact_id}`;
  };

  if (hasPermissions === null) {
    return <CheckingPermissionComponent />;
  }

  if (!hasPermissions) {
    return <NoPermissionErrorComponent />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout
        style={{
          backgroundColor: '#fff',
        }}
      >
        <HeaderComponent />
        <Layout
          hasSider
          style={{
            backgroundColor: '#fff',
          }}
        >
          <Layout
            style={{
              backgroundColor: '#fff',
            }}
          >
            <Card
              style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                backgroundColor: '#fff',
              }}
            >
              <TestCamera />
              <TestMicrophone />
            </Card>
          </Layout>
          <Content style={contentStyle}>
            <Text
              style={{
                color: '#000',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Please press start call button to start the call.
            </Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="primary"
                style={{
                  color: '#fff',
                  backgroundColor: '#000',
                  borderColor: '#000',
                }}
                onClick={startCall}
              >
                Start Call
              </Button>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
}

const contentStyle = {
  textAlign: 'center',
  minHeight: 320,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#fff',
};

export default JoinCallPage;
