import AgoraUIKit from 'agora-react-uikit';
import { Modal as AntdModal, Layout, Space } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../ENV';
import HeaderComponent from '../../components/header/header';
import SocketContext from '../../contexts/socket-context';
import './video-call.css';
const { Content } = Layout;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VideoCallComponent() {
  const { connectSocket } = useContext(SocketContext);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [isCallingModalOpen, setCallingModalOpen] = useState(true);
  const [isNoAgentFoundModalOpen, setNoAgentFoundModalOpen] = useState(false);
  const [videoCall, setVideoCall] = useState(false);
  const [socketIO, setSocketIO] = useState(null);
  const [agoraChannelId, setAgoraChannelId] = useState(null);
  const [agentProfile, setAgentProfile] = useState(null);
  const hasCalled = useRef(false);

  const query = useQuery();

  const name = query.get('name');
  const email = query.get('email');
  const agent = query.get('agent');
  const contact_id = query.get('contact_id');

  const props = {
    rtcProps: {
      appId: 'a26c63f3941c4aa99380a76b8693cd0e',
      channel: 'test',
      token: null,
      mode: 'rtc',
      codec: 'vp8',
      role: 'host',
    },
    callbacks: {
      EndCall: () => {
        setVideoCall(false);
        setVideoModalOpen(false);

        const URL = BASE_URL + '/socket/call-ended';

        fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callId: agoraChannelId,
            isAgent: false,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log('data', data);
          });

        // window.location.href = `/join-call?agent=${agent}`;
        window.location.href = `https://realmarket.com`;
      },
    },
  };

  const startCalling = useCallback(async () => {
    const soc = await connectSocket(name, email, contact_id);
    soc.emit('call-request', { toUser: agent, contact_id });
    setSocketIO(soc);
    openCallingModal();
  }, [agent, connectSocket, name, email, contact_id]);

  const getAgentProfile = useCallback(async () => {
    const URL = BASE_URL + '/agents/available-agents?email=' + agent;
    const response = await fetch(URL);
    const data = await response.json();
    if (data && Array.isArray(data) && data.length > 0) {
      setAgentProfile(data[0]);
    }
  }, [agent]);

  useEffect(() => {
    if (!hasCalled.current) {
      getAgentProfile();
      startCalling();
      hasCalled.current = true;
    }
  }, [startCalling, name, email, contact_id, agent, getAgentProfile]);

  useEffect(() => {
    if (socketIO) {
      socketIO.on('call-accept', (message) => {
        console.log('Received call accept:', message);

        const { callId } = message;

        setAgoraChannelId(callId);

        props.rtcProps.channel = callId;
        closeCallingModal();
        setVideoModalOpen(true);
        setVideoCall(true);
      });

      socketIO.on('call-end', (message) => {
        console.log('Received call end:', message);

        setVideoCall(false);
        setVideoModalOpen(false);

        //   window.location.href = `/join-call?agent=${agent}`;
        window.location.href = `https://realmarket.com`;
      });

      socketIO.on('no-agent-found', (message) => {
        console.log('Received no agent found:', message);
        closeCallingModal();
        setNoAgentFoundModalOpen(true);
      });
    }
  }, [socketIO, props.rtcProps, agent, contact_id, name, email]);

  const openCallingModal = () => {
    setCallingModalOpen(true);
  };

  const closeCallingModal = () => {
    setCallingModalOpen(false);
  };

  const handleOk_No_Agent_Found = () => {
    setNoAgentFoundModalOpen(false);
    window.location.href = `/join-call?name=${name}&email=${email}&agent=${agent}&contact_id=${contact_id}`;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout
        id="video-call"
        style={{
          backgroundColor: '#fff',
        }}
      >
        <HeaderComponent />
        <Content style={contentStyle}>
          <AntdModal
            title="No Agent Found"
            open={isNoAgentFoundModalOpen}
            onOk={handleOk_No_Agent_Found}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <p>Currently no agent is available to take your call.</p>
            <p>Please try again later.</p>
          </AntdModal>

          <AntdModal
            title=""
            centered
            open={isCallingModalOpen}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            width={'100px'}
          >
            <div className="calling-card">
              <div className="calling-header">
                <div className="animation">
                  <span className="icon ring"></span>
                  <div className="cercle one"></div>
                  <div className="cercle two"></div>
                  <div className="cercle three"></div>
                </div>

                <p className="phoneNumber">{agentProfile?.name}</p>
                <p className="calling">Calling</p>
                <p className="info-text">
                  Please wait for the agent to accept the call
                </p>
              </div>
            </div>
          </AntdModal>

          {isVideoModalOpen && (
            <div className="call-modal-overlay">
              <div className="call-modal-call">
                <div style={styles.container}>
                  {videoCall ? (
                    <AgoraUIKit
                      rtcProps={props.rtcProps}
                      callbacks={props.callbacks}
                      styleProps={{
                        localBtnContainer: {
                          backgroundColor: 'white',
                          width: '200px',
                        },
                        localBtnStyles: {
                          muteLocalVideo: {
                            backgroundColor: 'black',
                            color: 'white',
                            width: '50px',
                            height: '50px',
                          },
                          muteLocalAudio: {
                            backgroundColor: 'black',
                            color: 'white',
                            width: '50px',
                            height: '50px',
                          },
                          endCall: {
                            width: '50px',
                            height: '50px',
                            backgroundColor: 'red',
                          },
                        },
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </Content>
      </Layout>
    </Space>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
    objectFit: 'none',
  },
};

const contentStyle = {
  textAlign: 'center',
  minHeight: '85vh',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#fff',
};

export default VideoCallComponent;
